body {
  padding-top: @navbar-height;//+26;
    background-image:url("/public/img/bg-repeat.gif");
background-repeat:repeat-x;
    //-webkit-background-size: cover;
    //-moz-background-size: cover;
    //-o-background-size: cover;
    //background-size: cover;
    //background-position: center center;
}

@media only screen and  (max-width: @screen-md-min) {
  body {
    padding-top: 48px;
  }
}


html {
}

.plugin {
  display: block;
  background-color: @gray-lighter;
  padding: 0px;
}

[data-sr] {
  visibility: hidden;
}

/* Hamburger icon */
.navbar {
  span.icon-bar {
    color:red;
  }
}

u {
  text-decoration: none;
}

img.imgpage {
  border:1px solid white;
  //width:50%;
}

/* Thumbnail */
a.thumbnail {
  color:@text-color;
  &:hover, &.active, &:active {
    border-color:lighten(@thumbnail-border,20%);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6);
    color: inherit;
    text-decoration: none;
    & h2 {
      color: @brand-primary;
    }
  }
  & .image {
    & img {

    }
  }
  & h2, & h3 {
    padding: @padding-base-vertical @padding-base-horizontal;
  }
  & table {
    & tr {
      & td {
        &:first-child {
          font-weight:bold;
        }
      }
    }
  }
}

//a.gallery {
//  max-width:80%;
//}

@blockquote-font-size: (@font-size-base * 1.15);

// Blockquotes
blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @brand-primary;
  font-style: italic;
  color: lighten(@text-color, 20%);

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}


