// index
.preowned {
  position: relative;
  &.browse {
    & a.thumbnail {
      & .image {
        position: relative;
        & div.tagLabel {
          position: absolute;
          display: block;
          margin: 0px;
          right:0px;
          bottom: 10%;
          padding: @padding-base-vertical/2 @padding-base-horizontal;
          font-family: "Lato", Helvetica, Arial, sans-serif;
          font-weight: 900;
          background-color: @brand-secundary;
          color: #000;
          box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.4);

        }
      }
      & i {
        color: @brand-primary;
        &.normal {
          color: @text-color;
        }
        &.light {
          color: lighten(@gray-light, 30%);
        }
      }
      & h3 {
        margin: 0px;
        background-color: @brand-primary;
        color: #ffffff;
        font-size: @font-size-h3*0.8;
        //color:@brand-primary;
        padding: @table-cell-padding;
      }
      .table {
        margin-bottom: 0;
        & tr:first-child td {
          border-top: none;
        }
      }

    }
  }
}
