.carousel {
  position: relative;
  background-color: @gray-lighter;
}

.carousel-inner {
  background-color: @gray-lighter;
  > .item {
    //    background: url(images/bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
  }
}

.carousel-control {
  &.left {
    background-image: none;
  }
  &.right {
    background-image: none;
  }

}

// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption {
  display:none;
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: @carousel-caption-color;
  text-align: left;
  text-shadow: @carousel-text-shadow;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 8vh;
  line-height:1.2;
  & .btn {
    text-shadow: none; // No shadow for button elements in carousel-caption
  }
}
.carousel-caption-2 {
  display:none;
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 8%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: @brand-primary;//@carousel-caption-color;
  text-align: right;
  text-shadow: @carousel-text-shadow;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 6vh;
  line-height:1.2;
  & .btn {
    text-shadow: none; // No shadow for button elements in carousel-caption
  }
}

@media (max-width: @screen-sm-max) {
  .carousel-caption {
    bottom: 20%;
    font-size: 6vh;
  }
  .carousel-caption-2 {
    bottom: 12%;
    font-size: 3vh;
    font-weight: 400;
  }
  }