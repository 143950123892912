.mobile-wrapper {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  & .navmobile {
    background-color: @navbar-default-bg;
    & ul {
      white-space: nowrap;
      margin: 0;
      font-size:0;
      font-family: "Open Sans Condensed";
      text-transform: uppercase;
      & li {
        display: inline-block;
        margin: 0;
        padding: 0;
        white-space: normal;
        font-size:@font-size-base*1.2;
        & p.navmobile-text {
          margin: 0;
          padding: @padding-base-vertical @padding-base-horizontal;
          display: inline-block;
        }
        a {
          //background-color: @brand-primary;
          color: @text-color;
          display: inline-block;
          padding: @padding-large-vertical @padding-base-horizontal;
          &:hover {
            background-color: lighten(@navbar-default-bg,10%);//@brand-secundary;
            color: @brand-secundary;//lighten(@text-color,5%);
          }
        }
        &.active,&:active {
          a {
            background-color: darken(@brand-secundary,5%);
            color: @brand-secundary;//color: lighten(@text-color,5%);
          }
        }
      }
    }
  }
}

.navigation-wrapper {

  & .navbar {

    background-color: transparent;
    #gradient > .vertical(@gray-dark; @gray-darker);
    font-family: "Bangers";
    font-size: 22px;
    border: none;
    //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
    font-weight: 100;

    & .navbar-brand {
      max-width: 300px;
      padding: @navbar-height*0.20 @padding-base-horizontal*2 0 @padding-base-horizontal*2;
      transition: all 0.4s ease;
      //background-color: green;
      & img {
        display: inline-block;
        margin: 0;
        height: auto;
        width: 225px;
        transition: all 0.2s ease;
        //background-color: lime;
      }
    }
    & .navbar-header {
      & .navbar-toggle {
        margin: @navbar-height*0.34 @padding-base-horizontal*2 0 0;
        height: 100%;
      }
    }

    & ul.navbar-nav {
      margin: 0 !important;
      & li {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 0;
        padding: 0;
        & a {
          margin: 0px 0px;
          padding:auto 5px;
          text-transform: uppercase;
          text-align: center;
          & i {
            color: @navbar-default-link-color;
            font-size: 175%;
            display: block;
          }
        }
        &:hover a i {
          color: @brand-primary;
        }
        & .dropdown-menu {
          background-color: #ffffff;
          font-weight: 300;
          font-size: 16px;
          & li {
            border-bottom: 1px dotted @gray-lighter;
            & a {
              font-weight: 300;
              text-align: left;
              text-transform: none;

              color: @text-color;
              & i {
                color: @brand-primary;
                font-size: 100%;
              }
            }
          }
        }
        //& ul.dropdown-menu {
        //}

      }
    }
    &.shrink {
      //      .navbar-fixed-top();
      background-image: none;
      height: 90px;
      line-height: 90px;
      min-height: 90px;
      //overflow: visible;
      //#gradient > .vertical(#044839; #023129);
      background-color: #141414;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
      transition: all 0.2s ease;
      & .navbar-brand {
        padding: 0;
        //padding-bottom: 0;
        // margin: 0 0 0 @padding-base-horizontal*2;
        margin: 0;
        padding: 0 @padding-base-horizontal*2 0 @padding-base-horizontal*2;

        height: inherit;
        line-height: inherit;
        & img {
          display: inline-block;
          margin: 0;
          height: auto;
          width: 200px;
          transition: all 0.2s ease;
        }
      }
      & .navbar-header {
        & .navbar-toggle {
          height: 100%;
          margin: @navbar-height*0.20 @padding-base-horizontal*2 0 0;
        }
      }
      & ul.navbar-nav {
        & > li {
          & > a {
            padding-top: 0;
            padding-bottom: 0;
            height: inherit;
            line-height: inherit;
            & i {
              display: inline-block;
              font-size: inherit;

            }
          }
        }

        &.in {
          & > li {
            & > a {
              background-color: @navbar-default-bg;
              padding-top: 0;
              padding-bottom: 0;
              //height: 90px;
              //line-height: 90px;
            }
          }

        }
      }
    }
  }
}

ul.list.list-unstyled {
  & li {
    & a {
      display: block;
      color: @text-color; //@brand-primary;
      padding: @padding-base-vertical 0;
      font-size: @font-size-base*1.2;
      line-height: 1;
    }
    &:hover, &.active {
      & a {
        color: @brand-primary;
      }
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and  (max-width: @screen-lg-min) {
  .navbar {
    & ul.navbar-nav {
      & li {
        & a {
          font-size: 20px;
          //color:blue;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}

ul.list.list-unstyled.navlist {
  & li {
    border-bottom: 1px solid @gray-lighter;
    & a {
      color: @text-color;
      text-transform: uppercase;
      font-size: @font-size-base;
      line-height: 1.3;
      padding: @padding-base-vertical 0;
    }
    &:hover a {
      color: @brand-primary;
    }
    &.active a {
      color: @brand-primary !important;
      font-weight: bold;
    }
  }
}

