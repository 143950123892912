.wrapper {
  &.content-header {
//    background-color: @gray-lighter;
//    color:@text-color;
//    border-bottom:5px solid @brand-primary;
    background-color: @brand-primary;
    color:#ffffff;
    & h2 {
      margin:0;
    }
  }
}


.footer {
  //margin-top: 50px;
  background-color: @gray-darker;
  color: lighten(@gray-lighter,20%);
  & a {
    color: @gray-lighter;
    & span.small {
      display:inline-block;
      margin-bottom:4px;
      color: darken(@gray-light,20%);
    }
    &:hover {
      color: @brand-primary;
    }
  }
  & ul.list-unstyled {
    & li {
      & a {
        color: @gray-lighter;
        & span.small {
          display:inline-block;
          margin-bottom:4px;
          color: darken(@gray-lighter,10%);
        }
        &:hover {
          color: @brand-primary;
          & span.small {
            color: @brand-secundary;
          }
        }
      }
    }
  }

}
.address_footer {
  background-color: darken(@gray-darker,3%);
  color: lighten(@gray-lighter,20%);
  & a {
    color: @gray-light;
    & span.small {
      display:inline-block;
      margin-bottom:4px;
      color: darken(@gray-lighter,10%);
    }
    &:hover {
      color: @brand-primary;
    }
  }
  & ul.list-unstyled {
    & li {
      & a {
        color: @gray-light;
        & span.small {
          display:inline-block;
          margin-bottom:4px;
          color: darken(@gray-lighter,10%);
        }
        &:hover {
          color: @brand-primary;
        }
      }
    }
  }

}