.news {

  & ul.list-unstyled {
    & li {
      & a {
        font-size: @font-size-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}